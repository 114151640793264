.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#download {
  color: rgb(250, 217, 243);
}

#download a {
  color: rgb(118, 165, 253);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.download {
    gap: 5px;
    width: 100%;
    border: 1px solid white;
    border-radius: 5px;
    padding: 1em 0.3em;
    background: rgb(34, 66, 172);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
}

.download {
    background: rgb(105, 32, 99);
}
